<template>
  <div class="app flex-row align-items-center">

    <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">

      <b-row style="position:fixed;top:0px;height:95px;margin-top:58px;width:100%;  z-index: 1000" v-if="is_mobile == 'true'">
        <mdb-btn color="primary" style="right: 0px;position: fixed;border-radius: 122px;"  @click.native="VerFiltroMovil = true" size="lg"> <i class="fas fa-search"></i></mdb-btn>

      </b-row>

      <mdb-modal :show="VerFiltroMovil" @close="VerFiltroMovil = false">
        <mdb-modal-header>
          <mdb-modal-title>Filtro de búsqueda</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <div class="form-row" style="padding-top: 20px">

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Filtrar por Nombre</label>
              <input type="text" class="form-control" id="inputEmail4" v-model="selected_nombre" placeholder="Filtra por nombre">
            </mdb-col>

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Delegación</label>
              <select class="browser-default custom-select" v-model="selected_delegacion">
                <option selected value="">Todas</option>
                <option value="1">Agustinos Alicante</option>
              </select>
            </mdb-col>

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Tipo</label>
              <select class="browser-default custom-select" v-model="selected_tipo">
                <option selected value="">Todas las Novedades</option>
                <option value="2">Eventos Directo</option>
                <option value="1">Súbida de Vídeos</option>
              </select>
            </mdb-col>

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Orden</label>
              <select class="browser-default custom-select" v-model="selected_orden">
                <option selected value="DESC">Más nuevo primero</option>
                <option value="ASC">Más antiguo primero</option>
              </select>
            </mdb-col>
          </div>

        </mdb-modal-body>
        <mdb-modal-footer>
          <b-row>
              <mdb-btn color="danger"  @click="LimpiarFiltro()" >Limpiar</mdb-btn>
              <mdb-btn color="primary" @click="EjecutarFiltro()" style="min-width: 225px;" @click.native="VerFiltroMovil = false" >Buscar</mdb-btn>
              <mdb-btn color="secondary" @click.native="VerFiltroMovil = false">Cerrar</mdb-btn>
          </b-row>




        </mdb-modal-footer>
      </mdb-modal>


      <div id="principal" v-bind:class="{ 'col-md-9': this.is_mobile == 'false','col-md-12' : this.is_mobile == 'true' }">
        <mdb-row class="mt-5 align-items-center justify-content-start">
          <h4 class="demo-title"><strong>Novedades</strong></h4>
        </mdb-row>

        <hr />

        <mdb-row>


              <mdb-card   v-for="novedad in this.novedades" v-if="novedad.tipo_dato == 1 ||(novedad.tipo_dato == 2 && novedad.estado != 'FINALIZADO')" v-bind:style="GenerarEstiloNovedad(novedad)">

                <mdb-row  style="width: 100%;min-height: 200px">

                  <img v-if="novedad.tipo_dato === 1 && esta_en_vertical == true" @click="IraNovedad($event,novedad)" style="width:100%;max-height: 200px" v-bind:src="'https://api.agustinos.tv/api/imagenes/video/'+novedad.id + '/' + token+'?w=200'">
                  <img v-if="novedad.tipo_dato === 2 && esta_en_vertical == true" style="width: 100%;max-height: 200px;margin-left: 15px"  @click="IraNovedad($event,novedad)" v-bind:src="'https://api.agustinos.tv/api/imagenes/delegacion/' +novedad.delegacion_id + '/' + token+'?w=200'">


                    <img v-if="novedad.tipo_dato === 1 && esta_en_vertical == false" @click="IraNovedad($event,novedad)" style="width:20%;max-height: 200px" v-bind:src="'https://api.agustinos.tv/api/imagenes/video/'+novedad.id + '/' + token+'?w=200'">
                    <img v-if="novedad.tipo_dato === 2 && esta_en_vertical == false" style="width: 20%;max-height: 200px"  @click="IraNovedad($event,novedad)" v-bind:src="'https://api.agustinos.tv/api/imagenes/delegacion/'+novedad.delegacion_id +'/' + token+'?w=200'">

                  <div @click="IraNovedad($event,novedad)">
                <mdb-card-body v-if="novedad.tipo_dato === 1">
                  <mdb-card-title>{{novedad.title}}</mdb-card-title>
                  <mdb-card-text>{{novedad.details}}
                  <br /><br />
                  <h6>{{novedad.delegacion.nombre}}</h6>
                    <p> <span style="padding-right: 5px;"> {{novedad.views}} <mdb-icon far icon="eye"></mdb-icon></span><span style="padding-left: 5px"> {{novedad.likes}} <mdb-icon fas icon="heart"></mdb-icon></span></p>
                  </mdb-card-text>
                </mdb-card-body>
                  <mdb-card-body v-else-if="novedad.tipo_dato === 2">
                    <mdb-card-title>Retransmisión en directo : {{novedad.title}}</mdb-card-title>
                    <mdb-card-text style="color:white;">{{novedad.descripcion}}</mdb-card-text>
                    <br /><br />
                    <h6>{{novedad.delegacion.nombre}}</h6>
                    <p> <span style="padding-right: 5px;"> {{novedad.views}} <mdb-icon far icon="eye"></mdb-icon></span><span style="padding-left: 5px"> {{novedad.likes}} <mdb-icon fas icon="heart"></mdb-icon></span></p>
                  </mdb-card-body>
                  </div>
                </mdb-row>

              </mdb-card>
        </mdb-row>

        <mdb-row>
          <div style="width: 100%">
            <mdb-row style="width: 100%">
              <div class="col-md-3">
                <mdb-btn color="primary" style="margin-left: 35px;" v-if="pagina_actual > 1" @click="IrPaginaAnterior" size="lg">Atrás</mdb-btn>
              </div>
              <div class="col-md-6">
              </div>
              <div class="col-md-3">
                <mdb-btn color="primary" style="float: right;" @click="IrPaginaSiguiente" size="lg">Siguiente</mdb-btn>
              </div>

            </mdb-row>
          </div>
        </mdb-row>
      </div>
      <div v-if="this.is_mobile == 'false'" class="col-md-3">

      </div>

      <div v-if="this.is_mobile == 'false'" style="position: fixed;right: 0px;height: 100%;width: 25%;background: #e9ecef;margin-top: -5px;">
          <mdb-row class="mt-5 align-items-center justify-content-start" style="padding-left: 50px;padding-right: 50px">
            <b-row style="width: 100%">
              <div class="col-md-9">
                <h4 class="demo-title" style="color:black"><strong>Filtros</strong></h4>
              </div>
              <div class="col-md-3">
                <mdb-btn outline="primary" @click="EjecutarFiltro()" darkWaves size="sm">Buscar</mdb-btn>
              </div>

            </b-row>
          </mdb-row>



          <div class="form-row" style="padding-left: 50px;padding-right: 50px;padding-top: 20px;padding-right: 20px">

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Filtrar por Nombre</label>
              <input type="text" class="form-control" id="inputEmail4" v-model="selected_nombre" placeholder="Filtra por nombre">
            </mdb-col>

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Delegación</label>
              <select class="browser-default custom-select" v-model="selected_delegacion">
                <option selected value="">Todas</option>
                <option value="1">Agustinos Alicante</option>
              </select>
            </mdb-col>

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Tipo</label>
              <select class="browser-default custom-select" v-model="selected_tipo">
                <option selected value="">Todas las Novedades</option>
                <option value="2">Eventos Directo</option>
                <option value="1">Súbida de Vídeos</option>
              </select>
            </mdb-col>

            <mdb-col md="12" class="form-group">
              <label for="inputEmail4">Orden</label>
              <select class="browser-default custom-select" v-model="selected_orden">
                <option selected value="DESC">Más nuevo primero</option>
                <option value="ASC">Más antiguo primero</option>
              </select>
            </mdb-col>
          </div>





      </div>
    </b-row>

    <vue-cookie-accept-decline
            :ref="'myPanel1'"
            :elementId="'myPanel1'"
            :debug="false"
            :position="'bottom-left'"
            :type="'floating'"
            :disableDecline="true"
            :transitionName="'slideFromBottom'"
            :showPostponeButton="false"
            @status="cookieStatus"
            @clicked-accept="cookieClickedAccept"
            @clicked-decline="cookieClickedDecline">

      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">
       Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
      </div>


      <!-- Optional -->
      <div slot="acceptContent">
        ACEPTAR
      </div>
    </vue-cookie-accept-decline>
    </div>



</template>

<script>

  import { mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbIcon,
    mdbRow,
    mdbContainer,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbCol,
    mdbView,
    mdbBtn,
    mdbMask,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbCardGroup,  } from 'mdbvue';
  import ApiService from '@/services/api';

export default {
  name: 'Home',
  components: {
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbContainer,
    mdbRow,
    mdbIcon,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbView,
    mdbMask,
    mdbCol,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbCardGroup,
  },
  data() {
    return {
      VerFiltroMovil: false,
      token : localStorage.getItem('token'),
      novedades : [],
      estilo_row : 'width: 100%;margin: 10px 30px;',
      esta_en_vertical: false,
      pagina_actual : 1,
      is_mobile:'',
      selected_delegacion : null,
      selected_tipo : null,
      selected_orden: 'DESC',
      selected_nombre : '',
      delegaciones:null,
    }
  },
  methods: {


    LimpiarFiltro()
    {
      this.selected_delegacion = null;
      this.selected_tipo = null;
      this.selected_orden = 'DESC';
      this.selected_nombre  = '';
    },
    EjecutarFiltro()
    {
      if(this.is_mobile == true)
      {
        this.VerFiltroMovil = false;
      }
      this.pagina_actual = 1;
      this.ReloadData();
    },
    IraNovedad(e,dato)
    {
      console.log(dato);
      if(dato.tipo_dato == 1)
      {
        //Video
        this.$router.push({ path: 'video/' + dato.id });
      }
      else
      {
        this.$router.push({ path: 'canal/' + dato.canal_id });
        //
      }
    },
    GenerarEstiloNovedad(novedad)
    {
      let estilo_base = '';
      if(this.esta_en_vertical == false)
      {
        estilo_base = 'flex-direction: row;width: 100%;margin-left: 30px;margin-right:30px;margin-top: 10px;margin-bottom: 10px;cursor:pointer';
      }
      else
      {
        estilo_base = 'flex-direction: row;width: 100%;margin-left: 5px;margin-right:5px;margin-top: 10px;margin-bottom: 10px;cursor:pointer';
      }
      if(novedad.tipo_dato == 2)
      {
        estilo_base = estilo_base +  'color: white;  background: rgb(253, 148, 137)';
      }
      return estilo_base;

    },
    FechaSQLtoFechaES(entrada)
    {
      return  moment(String(entrada)).format('DD/MM/YYYY hh:mm:ss');
    },
    //Ordenar las novedades de más reciete a anterior (Son distintos tipos de objetos)
    compare(a, b) {

      var dateA = moment(a.created_at, "YYYY-MM-DD"); // replace format by your one
      var dateB = moment(b.created_at, "YYYY-MM-DD");

      if(dateA.diff(dateB) > 0) {
        return -1;
      }
      if(dateB.diff(dateA) > 0) {
        return 1;
      }
      return 0;

    },
    ReloadData()
    {
      ApiService.getNovedades(this.pagina_actual,this.selected_nombre,this.selected_delegacion,this.selected_tipo,this.selected_orden).then(response => {
        this.novedades = response.data.sort(this.compare);
        console.log('novedades:');
        console.log(this.novedades);
        console.log('Pagina Actual = ' + this.pagina_actual);
      });

    },
    IrPaginaSiguiente()
    {
      this.pagina_actual = this.pagina_actual + 1;
      this.ReloadData();
      var element = document.getElementById("app");
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    IrPaginaAnterior()
    {
      this.pagina_actual = this.pagina_actual - 1;
      this.ReloadData();
      var element = document.getElementById("app");
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    calcularAlturaBarraLateral()
    {

    }

    },
  created(){

    if(window.innerHeight > window.innerWidth)
    {
      this.esta_en_vertical = true;
      document.querySelector("html").style.fontSize = '.80rem';
      this.estilo_row = 'width: 100%;padding-right:5px;padding-left:5px;padding-top:10px;padding-bottom:10px;';
    }

    ApiService.isMobile().then(response => {
      console.log('isMobile');
      console.log(response);
      this.is_mobile = response.data.is_mobile;
      if(localStorage.getItem('is_mobile'))
      {

        this.is_mobile = localStorage.getItem('is_mobile');
        console.log('leyendo is_mobile : ' + this.is_mobile );
      }

      else {
        console.log('asignando is_mobile = ' + this.is_mobile);
        localStorage.setItem('is_mobile', this.is_mobile);
        if(this.is_mobile == true) {
          localStorage.setItem('res_video', '360p');
        }
        else {
          localStorage.setItem('res_video', '720p');
        }
        this.$forceUpdate();
      }
    });

    this.ReloadData();
  },
  computed: {

  }
  
}


</script>

<style>
  html {
    scroll-behavior: smooth;
  }
</style>
